@import '../variables/theme';

.dropdown {
  position: relative;
  font-size: 1.3rem;

  &.open {
    .dropdown__menu {
      display: block;
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    color: $header-menu-button-color;
    text-align: right;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
  }

  &__menu {
    position: absolute;
    right: 0;
    z-index: 2;
    display: none;
    //width: 100%;
    width: auto;
    min-width: 12.5rem;
    margin: 0.625rem 0;
    padding: 1.5625rem 0;
    list-style-type: none;
    background-color: #f5f4f4;
    box-shadow: 0.25rem 0.25rem 0.4375rem 0 rgba(210, 210, 210, 0.5);

    li {
      padding: 0.25rem 1.25rem;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background: #eee;
      }
    }
  }
}
