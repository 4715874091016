@mixin user-tiles-3() {
  @media screen and (max-width: 1420px) {
    @content;
  }
}

@mixin user-tiles-2() {
  @media screen and (max-width: 1140px) {
    @content;
  }
}

@mixin user-tiles-1() {
  @media screen and (max-width: 650px) {
    @content;
  }
}
