.mdc-text-field {
  --mat3-field-background: transparent;
  $root: &;

  padding: var(--mat3-field-padding, (0 16px)) !important;

  &--filled {
    --mat3-field-padding: 0;

    &:not(.mdc-text-field--disabled) {
      background-color: var(--mat3-field-background);

      .mdc-floating-label {
        color: var(--color-text-primary);
      }
    }

    &#{$root}--disabled {
      background-color: transparent;
    }

    &.mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-floating-label {
          top: 36px !important;
        }
      }
    }

    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix {
      margin-bottom: -16px;

      > .mat-icon {
        margin-left: 4px;
        padding: 0 !important;
      }
    }

    .mdc-floating-label {
      &--float-above {
        transform: translateY(-152%) scale(0.75) !important;
      }
    }
  }
}

.mat-mdc-form-field-focus-overlay {
  background-color: var(--mat3-field-background);
}

.mat-mdc-form-field-error-wrapper,
.mat-mdc-form-field-hint-wrapper,
.mat-form-field-subscript-wrapper {
  // TODO: Implement after baseline alignment fix
  //position: relative !important;
  padding: 0 !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-form-field-hint-wrapper {
  font-weight: 600;
}

.mat-form-field-hint-wrapper {
  line-height: 1.2;
}

.mat-mdc-form-field-subscript-wrapper {
  min-height: 8px;
}

.mat-mdc-form-field-bottom-align {
  &::before {
    content: none !important;
  }
}

.mat-form-field-appearance-fill {
  .mat-mdc-select-arrow-wrapper {
    transform: none !important;
  }
}

.mat-mdc-form-field-icon-prefix {
  padding: 0 !important;
}

.control-error-icon {
  --app-mat3-icon-color: var(--color-warn);

  display: none !important;
}

.mat-form-field-invalid {
  .control-error-icon {
    display: inline-block !important;
  }
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 2px !important;
}

mat-form-field-appearance-standard {
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    align-self: self-end;
    padding-bottom: 0.5em;
  }
}

.mat-form-field-underline {
  // TODO: Implement after baseline alignment fix
  //position: relative !important;
  //bottom: 0 !important;
}

.mat-form-field-subscript-wrapper {
  // TODO: Implement after baseline alignment fix
  //min-height: 12px;
}

.mat-form-field-wrapper {
  // TODO: Implement after baseline alignment fix
  //padding-bottom: 0 !important;
}
