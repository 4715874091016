$header-main-height: 80px;

$theme-dark-gray: #54565a;
$theme-white: #fff;
$theme-light-gray: #ccc;
$tenant-theme-color: #ee2e24;
$tenant-not-branded-color: #3b88c7;

$color-content-loader: rgba(0, 0, 0, 0.06);

$light-gray: #f2f3f8;
$dark-gray: #4b526d;
$red: #ee2e24;
$gray: #f5f5f5;
$green: #07c900;

//header-component
$header-text-color: var(--color-text-primary);
$header-user-initials-color: $theme-white;
$header-menu-button-color: var(--color-text-primary);

//navbar-component
$nav-item-link-color: var(--color-text-primary);
$nav-item-separator-line: rgba(0, 0, 0, 0.25);
$nav-item-active: #009da8;
$nav-child-item-background: $gray;

// claim-details-component

$header-border: #d8d8d8;
$claim-status-closed: $red;
$claim-status-canceled: $red;
$claim-status-na: $red;
$claim-status-open: #07c900;
$claim-status-pending: #07c900;
$claim-status-intake: #07c900;
$claim-status-validation: #07c900;
$claim-status-servicing: #07c900;
$claim-status-complete: #07c900;
$sub-text: #9ea4bb;
$timeline-header-background: #f8f8f9;

// timeline
$service-taken: #07c900;
$note: $tenant-not-branded-color;
$files: #f7ac08;
$letter: $tenant-theme-color;

//quick-search

$quick-search-header-separator-line: #efefef;

//user-stores
$disabled: #c9c9c9;
$selected: #b2b2b2;
$not-saved: #5ecdee;

//NEW COLORS
$color-blue: #00538b;
$color-blue-border: #00416d;
$color-green-text: #677312;
$color-orange-border: #ffb700;
$color-orange-background: #fff7e5;
$color-orange-text: #946310;
$color-border-gray: #edf1f7;
$color-border-light-gray: #e2e2e2;
$color-gray-600: #373737;
$color-unchecked-gray: rgba(0, 0, 0, 0.38);
$color-label-gray: rgb(0, 0, 0);
$color-table-hover: rgba(204, 230, 232, 0.2);
