@import '../variables/theme';

.sub-nav {
  display: inline-block;
  width: 100%;

  &__item {
    display: flex;
    padding: 0.4rem 0.6rem;
    margin-bottom: 0.4rem;
    cursor: pointer;
    border-left: 2px solid transparent;
    outline: 0;

    &.active {
      border-left: 2px solid var(--color-primary);
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }

  &__icon {
    --app-mat3-icon-size: 16px;

    display: inline-block;
    width: 18px;
    height: 18px;
    vertical-align: middle;
    border-radius: 50%;

    &--valid:hover {
      cursor: default;
    }

    &--valid {
      --app-mat3-icon-size: 16px;
      --app-mat3-icon-width: 18px;

      background: $green;
    }

    &--invalid {
      color: #fff;
      background: $red;
    }
  }

  &__title {
    font-size: 1.4rem;
    line-height: 1.6;
    vertical-align: middle;

    &--one-line {
      max-width: 168px;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    margin-left: 0.5rem;
    background: var(--color-primary);
    border-radius: 50%;

    span {
      color: #fff;
    }
  }
}

.nav-child {
  margin-left: 1.6rem;
}
