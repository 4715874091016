.snack-bar {
  --mat3-button-color: #fff;

  &_error {
    --mdc-snackbar-container-color: var(--color-error);
  }

  &_success {
    --mdc-snackbar-container-color: var(--color-success);
  }
}

.mat-mdc-snack-bar-action {
  color: #fff !important;
  font-size: 20px;
}
