.section {
  margin-bottom: var(--section-margin);

  //&:last-child {
  //  margin-bottom: 0;
  //}

  &__title {
    margin-bottom: var(--title-margin);

    &--theme {
      color: var(--color-primary);
    }
  }
}

.block {
  margin-bottom: var(--block-margin) !important;

  &__title {
    margin-bottom: var(--block-item-margin);
  }

  &__subtitle {
    margin-bottom: var(--subtitle-margin) !important;
  }

  &__item {
    & ~ & {
      margin-top: var(--block-item-margin);
    }

    &--theme {
      color: var(--color-primary);
    }
  }

  &__content {
    margin-bottom: var(--block-content-margin) !important;
  }
}
