.ql-wrapper {
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14);
}

.ql-toolbar {
  background: #f2f2f2;
  border: 0 !important;
  border-radius: 4px 4px 0 0;

  .ql-active {
    color: #fff !important;
    background: var(--color-primary) !important;
    border-radius: 2px;

    .ql-stroke {
      stroke: #fff !important;
    }

    .ql-fill {
      fill: #fff !important;
    }
  }
}

.ql-formats {
  button {
    & ~ & {
      margin-left: 4px;
    }
  }
}

.ql-container {
  border: 0 !important;
  border-radius: 0 0 4px 4px;
}

.ql-tooltip {
  z-index: 1;
  left: 0 !important;
}

.ql-editor {
  a {
    color: var(--color-primary);
  }

  em {
    font-style: italic;
  }
}
