@use '../../variables/responsive' as r;
@use '../../mixins/breakpoint' as b;

.mat-tab-nav-bar,
.mat-mdc-tab-header {
  border: 0;
}

.mat-mdc-tab,
.mat-tab-link {
  height: auto !important;
  padding: 8px 0 !important;
  color: var(--color-secondary);
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 2px solid currentColor;
  opacity: 1 !important;

  @include b.breakpoint(r.$screen-tablet-lg) {
    min-width: 180px;
    padding: 8px 24px !important;
  }

  & ~ & {
    margin-left: 20px;
  }
}

.mat-mdc-tab-label-container {
  flex-grow: 0 !important;
}

.mat-mdc-tab {
  border-bottom: solid 2px transparent;
}

.mdc-tab {
  &--active {
    color: var(--color-primary);
    font-weight: 600;
    border-bottom: solid 2px var(--color-primary);
  }

  &__text-label {
    transition: none !important;
  }
}

.mat-mdc-tab-header {
  margin-bottom: 16px;
}

.mat-mdc-tab-group, .mat-mdc-tab-body-wrapper {
  height: var(--app-mat3-tab-group-body-height, auto);
}

.mat-mdc-tab-body-content {
  padding: var(--app-mat3-tab-group-body-padding, 0);
}
