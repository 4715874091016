@use '../variables/responsive' as r;
@use '../mixins/breakpoint' as b;
@use '../mixins/responsive' as mr;

$sidebar-width: 264px;
$cell-topic-max-width: 148px;
$cell-contentType-max-width: 220px;
$cell-category-max-width: 140px;
$cell-scope-max-width: 142px;
$cell-date-max-width: 168px;
$cell-date-min-width: 90px;
$cell-one-action: 32px;
$cell-two-actions: 64px;

._common-grid__header-grey {
  .grid__header {
    background-color: #ebebeb !important;
    border-bottom: solid 1px #00abf2;

    .grid__row {
      .grid__cell {
        border-top: solid 2px #edf1f7 !important;
        border-bottom: solid 2px #edf1f7 !important;
      }
    }
  }
}

.system-admin-grid-responsive {
  .grid__cell.grid__cell--question,
  .grid__cell.grid__cell--title {
    width: inherit;
    min-width: inherit;
    max-width: 1000px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mr.user-tiles-3() {
      width: 50%;
      min-width: 120px;
      max-width: 50%;
      white-space: normal;
    }
  }
}

.system-admin-portal-features-grid,
.system-admin-faqs-grid,
.system-admin-videos-grid {
  .grid__cell--category {
    width: $cell-category-max-width;
    min-width: $cell-category-max-width;
    max-width: $cell-category-max-width;
    white-space: nowrap !important;

    @include mr.user-tiles-1() {
      width: inherit;
      min-width: 80px;
      max-width: inherit;
      white-space: normal !important;
    }
  }
}

.system-admin-portal-features-grid {
  .grid__cell.grid__cell--title {
    max-width: calc(100vw - #{$cell-scope-max-width} - (3 * #{$cell-date-max-width}) - #{$sidebar-width} - 115px);
  }

  .grid__cell--expiresOn,
  .grid__cell--fromDate,
  .grid__cell--toDate,
  .grid__cell--scope {
    width: $cell-date-max-width;
    min-width: $cell-date-min-width;
    max-width: $cell-date-max-width;
    white-space: nowrap !important;

    @include mr.user-tiles-1() {
      width: inherit;
      min-width: 88px;
      max-width: inherit;
      white-space: normal !important;
    }
  }

  .grid__cell--scope {
    width: 200px;
    min-width: $cell-date-min-width;
    max-width: 200px;
    white-space: nowrap !important;

    @include mr.user-tiles-1() {
      width: inherit;
      min-width: 88px;
      max-width: inherit;
      white-space: normal !important;
    }
  }
}

.system-admin-faqs-grid {
  .grid__cell.grid__cell--question {
    max-width: calc(
      100vw - #{$cell-scope-max-width} - #{$cell-topic-max-width} - #{$cell-contentType-max-width} - #{$sidebar-width} -
      115px
    );
  }

  .grid__cell--topic {
    width: $cell-topic-max-width;
    min-width: $cell-topic-max-width;
    max-width: $cell-topic-max-width;
    white-space: nowrap !important;

    @include mr.user-tiles-1() {
      width: inherit;
      min-width: 88px;
      max-width: inherit;
      white-space: normal !important;
    }
  }

  .grid__cell--contentType {
    width: $cell-contentType-max-width;
    min-width: $cell-contentType-max-width;
    max-width: $cell-contentType-max-width;
    white-space: nowrap !important;

    @include mr.user-tiles-1() {
      width: inherit;
      min-width: 88px;
      max-width: inherit;
      white-space: normal !important;
    }
  }
}

.system-admin-videos-grid {
  .grid__cell.grid__cell--title {
    max-width: calc(
      100vw - #{$cell-scope-max-width} - #{$cell-category-max-width} - #{$cell-contentType-max-width} - #{$sidebar-width} -
      115px
    );
  }

  .grid__cell--contentType {
    width: $cell-contentType-max-width;
    min-width: $cell-contentType-max-width;
    max-width: $cell-contentType-max-width;
    white-space: nowrap !important;

    @include mr.user-tiles-1() {
      width: inherit;
      min-width: 88px;
      max-width: inherit;
      white-space: normal !important;
    }
  }
}

.system-admin-clients-grid {
  .grid__cell.grid__cell--delete-action {
    width: $cell-two-actions;
    min-width: $cell-two-actions;
    max-width: $cell-two-actions;
  }

  .grid__cell.grid__cell--accountNumber {
    width: inherit;
    min-width: inherit;
    max-width: inherit;

    @include mr.user-tiles-1() {
      width: 80px;
      min-width: 80px;
      max-width: 80px;
    }
  }

  .grid__cell--tenantName,
  .grid__cell--accountNumber,
  .grid__cell--clientId {
    white-space: nowrap !important;

    @include mr.user-tiles-2() {
      white-space: normal !important;
    }
  }
}

.system-portals-mappings-grid {
  .grid__cell--retailerLocationID {
    width: $cell-contentType-max-width;
    min-width: $cell-contentType-max-width;
    max-width: $cell-contentType-max-width;
    white-space: nowrap !important;

    @include mr.user-tiles-2() {
      width: inherit;
      min-width: 120px;
      max-width: inherit;
      white-space: normal !important;
    }
  }

  .grid__cell--createDate,
  .grid__cell--modifyDate {
    width: inherit;
    min-width: $cell-date-min-width;
    max-width: inherit;
    white-space: nowrap !important;

    @include mr.user-tiles-2() {
      width: $cell-date-min-width;
      min-width: $cell-date-min-width;
      max-width: $cell-date-min-width;
      white-space: normal !important;
    }
  }

  .grid__cell.grid__cell--delete-action {
    width: $cell-one-action;
    min-width: $cell-one-action;
    max-width: $cell-one-action;
  }
}
