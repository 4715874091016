@use '../../mixins' as i;
@use '../../variables' as v;

.mdc-list-item {
  & ~ & {
    border-top: 1px solid var(--mat3-select-list-item-border-color, #e0e0e0);

    @include i.breakpoint(v.$screen-tablet-lg) {
      border-top: none;
    }
  }

  &__primary-text {
    width: var(--mat3-select-list-item-width, auto);
  }
}

// Legacy
.mat-form-field-appearance-standard.mat-form-field-has-label .mat-select-arrow-wrapper {
  transform: translateY(0) !important;
}
