// Start from
/* stylelint-disable */
$screen-phone: 577px;
/* stylelint-enable */
$screen-tablet: 769px;
$screen-tablet-lg: 1024px;
$screen-desktop: 1280px;
$screen-wide: 1600px;

$print-screen-a5: 380px;
