.mdc-checkbox {
  --_th-mat-checkbox-size: var(--th-mat-checkbox-size, 18px);
}

.mat-mdc-checkbox {
  &:hover {
    .mdc-checkbox__background {
      border-color: var(--color-primary) !important;
    }
  }

  &.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      background-color: var(--color-primary) !important;
      border-color: var(--color-primary) !important;
    }
  }

  .mdc-checkbox {
    padding: var(--th-mat-checkbox-padding, 0) !important;

    .mdc-checkbox__native-control {
      width: var(--_th-mat-checkbox-size) !important;
      height: var(--_th-mat-checkbox-size) !important;

      &:enabled:indeterminate {
        ~.mdc-checkbox__background {
          background-color: var(--color-primary) !important;
          border-color: var(--color-primary) !important;
        }
      }
    }

    .mdc-checkbox__background {
      top: 0 !important;
      left: 0 !important;
    }
  }
}

.mat-mdc-checkbox-touch-target {
  width: var(--_th-mat-checkbox-size) !important;
  height: var(--_th-mat-checkbox-size) !important;
}
