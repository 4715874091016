@import '../variables';

$tile-width: 256px;
$action-not-required-padding-left: 2px;

.dashboard-tile {
  $root: &;
  width: $tile-width;
  margin: 0 1rem 1rem 0;
  cursor: pointer;
  background: var(--color-secondary);
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(83, 97, 255, 0.05);

  &_theme {
    background: var(--color-primary);
  }

  &__header {
    display: flex;
    padding: 2rem 1.6rem;
    font-size: 1.5rem;
    color: #fff;
    border-bottom: 1px solid #fff;
    align-items: center;
    cursor: pointer;
  }

  &__header-icon {
    display: flex;
    width: 2.6rem;
    height: 2.6rem;
    margin-right: 1rem;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    align-items: center;
    justify-content: center;
  }

  &__header-labels-section {
    display: flex;
    height: 29px;
    flex-direction: column;
    justify-content: center;
  }

  &__header-label-required {
    margin-top: 4px;
    font-size: 12px;
    font-style: italic;
  }

  &__actions-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    min-width: 0;
    height: 100%;
  }

  &__actions-section {
    align-self: flex-start;
    width: 100%;
    min-width: 0;
    font-size: 12px;
    cursor: default;
  }

  &__action {
    display: flex;
    gap: 4px;
    align-items: center;
    position: relative;
    line-height: 16px;
    padding-left: 4px;
    cursor: pointer;

    & ~ & {
      margin-top: 4px;
    }

    &:hover {
      text-decoration: underline;
    }

    &--not-required {
      padding: 4px;
      border-radius: 4px;
      border: solid 1px #ffb700;
      background-color: #fff7e5;
      color: var(--color-primary);
    }
  }

  &__action-count {
    font-weight: 600;
    min-width: 24px;
    text-align: right;
  }

  &__action-icon-alert {
    --app-mat3-icon-size: 12px;
    --app-mat3-icon-width: 16px;
    --app-mat3-icon-height: 12px;

    width: 16px;
    height: 12px;
  }

  &__card-icon {
    --app-mat3-icon-size: 20px;
    --app-mat3-icon-width: 16px;
  }

  &__content {
    display: flex;
    align-items: center;
    height: 90px;
    padding: 12px 16px;
    font-size: 1.6rem;
    color: #fff;
    cursor: pointer;
  }

  &__count-section {
    cursor: pointer;
  }

  &__count {
    font-size: 3.5rem;
  }

  &__loader {
    color: $red;
  }

  &--composite {
    width: 320px;
    min-width: $tile-width;

    #{$root}__header {
      padding: 1.9rem 2rem 1.8rem;
    }

    #{$root}__content {
      cursor: default;
    }

    #{$root}__count-section {
      cursor: pointer;
    }

    #{$root}__action {
      cursor: pointer;
    }
  }
}

._pagination-without-first-last-arrows {
  .pagination__previous {
    .pagination__button:first-child {
      display: none !important;
    }
  }

  .pagination__next {
    .pagination__button:last-child {
      display: none !important;
    }
  }

  .pagination__counter {
    display: none !important;
  }
}

._revert-reset-styles {
  ol,
  ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  ::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0 !important;
    text-align: start !important;
    text-align-last: start !important;
  }

  blockquote {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 16px;
    border-left: 4px solid #ccc;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

    &::before, &::after {
      content: none !important;
    }
  }

  q {
    display: inline;
  }

  blockquote::before,
  q::before {
    content: open-quote;
  }

  blockquote::after,
  q::after {
    content: close-quote;
  }

  blockquote, q {
    quotes: revert;
  }

  table {
    display: table;
    box-sizing: border-box;
    border-spacing: 2px;
    border-collapse: separate;
  }

  em {
    font-style: italic;
  }
}
