@import '../variables';

._common-table {
  &__required-parts {
    .table__scroll-wrapper {
      margin: 0 !important;
    }
  }
}

._tech-invoices-table {
  .mat-table {
    tr {
      .mat-column-invoiceNumber {
        max-width: 120px;
        min-width: 120px;
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;

        .table-cell__type-inline {
          width: 112px;
        }

        @include breakpoint($screen-tablet) {
          min-width: 180px;
          width: 180px;
          max-width: 180px;

          .table-cell__type-inline {
            width: 172px;
          }
        }

        @include breakpoint($screen-desktop) {
          min-width: fit-content;
          width: 20%;
          max-width: 20%;

          .table-cell__type-inline {
            width: auto;
          }
        }
      }
    }
  }

}
