.mat-calendar-controls {
  .mat-calendar-previous-button,
  .mat-calendar-next-button {
    --mat3-button-width: 40px;
    --mat3-button-min-width: auto;
    --mat3-button-height: 40px;
  }

  .mat-icon-button[disabled] {
    color: #9ea4bb;
    cursor: not-allowed;
  }
}

.mat-datepicker-content {
  .mat-mdc-icon-button:active,
  .mat-mdc-icon-button:focus {
    .mat-mdc-button-persistent-ripple::before {
      opacity: 0;
    }
  }
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover {
  & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: #fff;
  }
}

// Legacy
.mat-calendar-body-cell {
  &:hover, &:focus {
    &:not(.mat-calendar-body-disabled),
    .mat-calendar-body-today:not(.mat-calendar-body-selected) {
      .mat-calendar-body-cell-content {
        color: #fff;
        background: var(--color-primary);
        border-color: var(--color-primary);
      }
    }
  }
}

.mat-calendar-body-selected {
  color: #fff;
  background: var(--color-primary);
  border-color: var(--color-primary) !important;
}

.mat-calendar-previous-button,
.mat-calendar-next-button {
  color: var(--color-primary);

  &::after {
    margin: 10px !important;
  }
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  color: var(--color-text-dark);
  background: #fff;
  border-color: var(--color-text-secondary) !important;

  &:hover {
    color: #fff;
    background: var(--color-primary);
    border-color: var(--color-primary) !important;
  }
}

.mat-calendar-body-label {
  vertical-align: middle;
}

.mat-calendar-period-button {
  --mat3-button-min-width: auto;
}
