._tab-group--select-plan-or-purchase {
  height: 100%;

  .mat-tab-group {
    height: 100%;
  }

  .mat-tab-header {
    padding: 0 16px;
    background: #fff;
    margin-bottom: 0;
  }

  .mat-tab-body-wrapper {
    height: 100%;
  }

  .mat-tab-body {
    height: 100%;
  }
}

.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: 0;
}

.mat-tab-label-active {
  font-weight: 600;
  color: var(--color-primary);
}
