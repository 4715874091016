.mat-expansion-panel {
  $root: &;

  border-radius: var(--app-mat3-expansion-panel-border-radius, 10px) !important;
}

.mat-expansion-panel-header {
  height: auto;
  min-height: 48px;

  &.mat-expanded {
    height: auto !important;
    min-height: 64px;
  }

  .mat-content {
    align-items: center;
  }

  ._common-indicator {
    transform: rotate(0deg);
  }
}

.mat-expansion-panel-header.mat-expanded ._common-indicator {
  transform: rotate(180deg);
}

.mat-expansion-panel-header-title {
  color: var(--color-primary);
  font-weight: 600;
  font-size: var(--mat3-expansion-panel-title-font-size, 18px);
}

.mat-expansion-panel-body {
  padding: 20px !important;
  border-top: var(--app-mat3-expansion-panel-body-border-top, (solid 1px #7B7D80));
}
