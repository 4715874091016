@use '../../th-common/scss/main';

:root {
  --mat3-button-font-size: 16px;
  --mat3-button-min-width: 200px;

  // Table
  --th-table-header-background: #fff;
  --th-table-header-border-left-size: 0;
  --th-table-header-border-right-size: 0;
  --th-table-border: solid 1px #e0e0e0, none, solid 1px #e0e0e0, none;
  --th-table-border-radius: 0;
  --th-table-box-shadow: 0 2px 4px 0 rgba(196, 196, 196, 0.26);
  --th-table-row-header-font-size: 12px;
  --th-table-row-header-font-weight: 600;
  --th-table-header-cell-color: #4b526d;
  --th-table-cell-font-size: 14px;
  --th-table-cell-font-weight: 600;
  --th-table-cell-color: #4b526d;
  --th-table-row-border-color: #e0e0e0;
}
