._common-mat-form-field {
  &--quick-search {
    .mat-input-element {
      font-size: var(--app-quick-search-font-size, 14px);
    }

    .mat-form-field-prefix {
      margin-right: 8px;
      padding-bottom: 3px;
    }
  }

  &_grid-search {
    .mat-form-field {
      width: 400px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  &_hide-errors {
    .mat-form-field-subscript-wrapper {
      display: none;
    }
  }

  &_expand-hint {
    .mat-mdc-form-field-hint-wrapper {
      position: static;
      font-weight: 400;
    }
  }

  &_color-error {
    input.mat-input-element {
      color: var(--color-warn);
      font-weight: 800;
    }

    .dollar {
      left: -2px;
      color: var(--color-warn);
    }
  }
}

._common-table-td {
  &--cost {
    .mat-form-field-flex {
      padding: 0 !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-wrapper,
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }

    .mat-form-field-infix {
      display: flex;
      gap: 4px;
      padding: 0 !important;
      border-top: 0 !important;
    }

    .mat-form-field-wrapper .mat-form-field-underline .mat-form-field-ripple {
      height: 0 !important;
    }

    .mat-form-field-label-wrapper {
      padding: 0 !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-underline,
    .mat-form-field-underline,
    .mat-form-field-wrapper .mat-form-field-underline {
      height: 0 !important;
    }
  }
}

._common-theme-field {
  .mat-form-field-label {
    color: var(--color-primary);
  }
}
