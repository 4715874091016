@use '../../mixins' as m;

.mat-mdc-dialog-content {
  max-height: var(--mat3-dialog-content-max-height, 65vh) !important;
  padding: var(--mat3-dialog-content-padding, (12px 20px)) !important;
}

.mat-mdc-dialog-actions {
  padding: var(--mat3-dialog-actions-padding, (12px 20px)) !important;
  border-top: var(--mat3-dialog-actions-border-top, none) !important;
}

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-size: 14px;

  .mdc-dialog__content {
    color: var(--color-text-primary) !important;
  }

  .mdc-dialog__container {
    @include m.print {
      display: block;
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .mdc-dialog__surface {
    @media print {
      box-shadow: none !important;
    }
  }
}

.mat-mdc-dialog-title {
  --color-text-primary: var(--color-primary);

  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 16px !important;
  color: var(--color-primary) !important;
  line-height: 2 !important;
  background: #fff;
  border-bottom: 1px solid #d8d8d8;

  &::before {
    display: none !important;
    content: none !important;
  }
}

.cdk-overlay-pane {
  @include m.print {
    display: block;
    width: 100% !important;
    max-width: 100% !important;
    height: auto;
  }
}
