.mdc-switch {
  $root: &;

  &__ripple,
  &__icons {
    display: none;
  }

  &__shadow {
    background: #fff;
  }

  &__handle-track {
    left: 2px !important;

    #{$root}--selected & {
      left: -2px !important;
    }
  }
}
