@import '../variables';

$tiles-margin: 20px;

.quick-search {
  padding: 4px;

  &__container {
    display: grid;
    grid-gap: $tiles-margin;
    grid-template-columns: 1fr;

    @include breakpoint($screen-desktop) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__tile {
    width: 100%;
    height: 280px;
    padding: 12px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  }

  &__scratch-tile {
    width: 100%;
    padding: 12px;
    margin-bottom: $tiles-margin;
    border: 1px solid var(--color-primary);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  }

  &__consumer {
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #efefef;
  }
}
