:root {
  --base-font: 'Gotham', 'Open Sans', sans-serif;
  --color-primary: #012169;
  --color-primary-rgba: rgba(1, 33, 105, 1);
  --color-primary-rgba-05: rgba(1, 33, 105, 0.05);
  --color-public-bg-rgba: rgba(1, 33, 105, 1);
  --color-secondary: #71984a;
  --color-text-primary: #231f20;
  --color-text-dark: #231f20;
  --color-text-secondary: #9ea4bb;
  --color-success: #71984a;
  --color-error: #ee2e24;
  --color-warn: #ee2e24;
  --color-red-attention: #ca6477;
  --color-red-help: #c5303a;
  --color-red-help-light: #FCE9EB;
  --color-red-light-background: #f9eaeb;
  --color-required: #8a9918;
  --color-green-background: #f3f4e7;
  --color-optional: #7f7f7f;
  --subtitle-margin: 8px;
  --title-margin: 12px;
  --section-margin: 20px;
  --block-margin: 32px;
  --block-content-margin: 24px;
  --block-item-margin: 16px;
  --theme-background-gray: #fafafa;
}
