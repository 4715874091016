.mat-mdc-button-base {
  --_mat3-button-color: var(--mat3-button-color, var(--color-primary));
  --_mat3-button-width: var(--mat3-button-height, 48px);
  --_mat3-flat-button-background: var(--mat3-flat-button-background, var(--color-primary));
  --_mat3-stroked-button-background: var(--mat3-stroked-button-background, transparent);

  width: var(--mat3-button-width, auto) !important;
  min-width: var(--mat3-button-min-width, 64px) !important;
  height: var(--_mat3-button-width) !important;
  padding: var(--app-mat3-button-padding, (0 16px)) !important;
  border-radius: var(--mat3-button-border-radius, 10px) !important;

  &.mat-mdc-unelevated-button {
    --mat3-button-color: #fff;
    --mat3-button-background: var(--_mat3-flat-button-background);

    box-shadow: var(--app-mat3-button-box-shadow, none);

    &.mat-warn {
      --mat3-button-background: var(--color-warn);
    }
  }

  &.mat-mdc-outlined-button {
    --mat3-button-background: var(--_mat3-stroked-button-background);

    border-color: var(--_mat3-button-color) !important;
    box-shadow: var(--app-mat3-button-box-shadow, none);

    &.mat-warn {
      --mat3-button-color: var(--color-warn);
    }
  }

  &.mat-mdc-button {
    font-weight: 600;
  }

  &:disabled {
    background-color: var(--mat3-button-disabled-background, rgba(0, 0, 0, 0.12)) !important;
    border-color: var(--mat3-button-disabled-background, rgba(0, 0, 0, 0.12)) !important;
    cursor: not-allowed !important;
  }

  &.mdc-icon-button {
    --app-mat3-button-padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--app-mat3-icon-button-width, 32px) !important;
    min-width: var(--app-mat3-icon-button-min-width, 32px) !important;
    height: var(--app-mat3-icon-button-height, 32px) !important;

    &:disabled {
      --mat3-button-disabled-background: transparent !important;
    }
  }

  &:not(:disabled) {
    cursor: pointer;

    &:not(.mdc-icon-button) {
      color: var(--_mat3-button-color) !important;
      background-color: var(--mat3-button-background) !important;
    }
  }

  &:not(.mdc-icon-button) > .mat-icon {
    --app-mat3-icon-size: var(--app-mat3-button-icon-size, 1.125rem);

    margin-right: var(--app-mat3-button-icon-margin-right) !important;
    margin-left: var(--app-mat3-button-icon-margin-left) !important;
    vertical-align: middle;
  }

  &:active, &.cdk-program-focused {
    .mat-mdc-button-persistent-ripple {
      &::before {
        opacity: 0;
      }
    }
  }

  &.mat-mdc-icon-button {
    &.mat-mdc-button-base {
      padding: 0;
    }

    .mat-mdc-button-touch-target {
      width: var(--mat3-button-width, auto) !important;
      height: var(--mat3-button-height, 48px) !important;
    }
  }

  .mdc-button {
    &__label {
      font-weight: var(--mat3-button-font-weight, 600);
      font-size: var(--mat3-button-font-size, 14px);
      text-transform: var(--app-mat3-button-label-transform, capitalize);
      vertical-align: middle;
    }
  }

  .mat-mdc-button-touch-target {
    height: var(--mat3-button-touch-height, var(--_mat3-button-width)) !important;
  }
}
