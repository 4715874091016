.mat-sort-header-disabled {
  .mat-sort-header-arrow {
    display: none;
  }
}

.mat-sort-header-arrow {
  opacity: 0 !important;
}

.mat-mdc-header-cell {
  &:hover {
    .mat-sort-header-arrow {
      opacity: 0.54 !important;
    }

    .mat-sort-header-sorted {
      .mat-sort-header-arrow {
        opacity: 1 !important;
      }
    }
  }
}

.mat-sort-header-sorted {
  .mat-sort-header-arrow {
    opacity: 1 !important;
  }
}

.mat-mdc-row {
  height: 46px !important;
}
