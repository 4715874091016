@use '../variables' as v;
@use '../mixins' as m;

$padding: 16px;
$header-height: 65px;
$dialog-nav-width: 25rem;

.dialog {
  $root: &;

  display: block;
  height: 100%;
  background: #fff;

  &--side {
    #{$root}__content {
      height: calc(100% - #{$header-height});
      padding: 0 $padding;
    }

    #{$root}__container {
      padding-top: $padding;
    }

    .section {
      &:last-child {
        padding-bottom: 16px;
      }
    }
  }

  &--header-alert {
    #{$root}__content {
      height: calc(100% - 147px);
    }
  }

  &--short {
    width: 55vw;
  }

  &__header {
    padding: 16px;
    color: var(--color-primary);
    border-bottom: 1px solid v.$header-border;

    h2 {
      color: var(--color-primary);
    }

    &--no-border {
      border-bottom: none;
    }

    &--no-pb {
      padding-bottom: 0;
    }

    &--flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__title {
    display: block;
    margin: 0;
    color: var(--color-primary);

    &--uppercase {
      text-transform: uppercase;
    }

    &--with-subtitle {
      display: flex;
      align-items: flex-start;

      #{$root}__main-title {
        width: calc(#{$dialog-nav-width} + #{$padding});
      }
    }
  }

  &__main-title {
    width: 100%;
    color: var(--color-primary);
  }

  &__subtitle {
    width: calc(100% - #{$dialog-nav-width} - #{$padding} - #{$padding});
    padding-left: 6px;
    font-size: 14px;
  }

  &__container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 250px minmax(0, 1fr);
    height: 100%;

    @include m.print() {
      display: block !important;

      ::ng-deep table {
        white-space: normal;
        table-layout: fixed;

        * {
          white-space: normal !important;
        }
      }
    }

    &__grid-wide {
      grid-template-columns: 310px minmax(0, 1fr);
    }

    &__block {
      display: block;
    }

    &--small-gap {
      grid-gap: 4px;
    }
  }

  &__scroll-view {
    width: 100%;
    height: calc(100vh - 80px);
    padding: 0 16px;
    overflow: auto;
  }

  &__close {
    --mat3-button-width: 28px;
    --app-mat3-button-icon-size: 24px;
    --mat3-button-min-width: 24px;
    --app-mat3-button-padding: 0;

    color: var(--color-primary);
    cursor: pointer;
  }

  &__nav {

    @include m.do-not-print();

    width: $dialog-nav-width;
    min-width: $dialog-nav-width;
    max-width: $dialog-nav-width;
    margin-right: 3.2rem;
    padding: 0 0 $padding $padding;
  }

  &__controls {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    min-width: 305px;
  }

  &__content {
    position: relative;
    width: 100%;
    padding: $padding;

    &--black {
      color: var(--color-text-primary);
    }
  }

  &__switch-header {
    display: flex;
    align-items: center;
  }

  &__same-switch {
    margin-left: 2rem;
  }

  &__submit-button {
    margin: 1rem 0;
  }
}

._common-dialog {
  max-height: 80vh;
}

.cdk-global-overlay-wrapper {
  overflow: auto;

  @include m.breakpoint(v.$screen-tablet-lg) {
    overflow: hidden;
  }

  @include m.print {
    position: relative;
    display: block;
    height: auto;
    overflow: initial;
  }
}

.cdk-overlay-container {
  @media print {
    position: static !important;
    height: auto;
  }
}

.mdc-dialog__surface {
  @media print {
    box-shadow: none;
  }
}

._common-side-dialog {
  &_clear-view {
    .dialog {
      &__header {
        align-items: center !important;
        padding: 16px !important;
        border-bottom: 0 !important;
      }

      &__header-close-icon {
        margin-top: 0 !important;
      }

      &__content {
        padding: 0 !important;
      }
    }
  }

  &_gray {
    .dialog {
      background-color: #dedede !important;
    }

    .dialog__header {
      background: #ffff;
    }

    .mat-tab-header {
      background: #fff;
    }
  }
}

.side-dialog {
  display: grid;
  grid-template-rows: auto 1fr;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    color: var(--color-primary);
    line-height: 2;
    background: #fff;
    border-bottom: 1px solid #d8d8d8;

    h2 {
      color: var(--color-primary);
    }
  }

  &__close {
    cursor: pointer;
  }

  &__content {
    width: 100%;
    height: auto;
  }

  &__content-scroll {
    display: inline-block;
    width: calc(100% - 282px);
    height: calc(100vh - 81px);
    padding: 16px;
    vertical-align: top;
  }

  &__nav {
    display: inline-block;
    width: 25rem;
    min-width: 25rem;
    max-width: 25rem;
    margin-right: 3.2rem;
    padding: 16px 0 16px 16px;
    vertical-align: top;
  }
}
