@import '../variables';

._common-loader {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;

  ._common-button_is-loading & {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::before {
    width: 17px;
    height: 17px;
    content: '';
    border: 2.5px solid $light-gray;
    border-top-color: var(--color-primary);
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
