@import '../variables/responsive';
@import 'breakpoint-sass/stylesheets/breakpoint';

.form {
  width: 100%;

  &--readonly {
    display: flex;
    max-width: 52rem;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;

    .field {
      margin-bottom: 1.6rem;
    }
  }

  &__fields--public {
    width: 100%;
    color: #fff;

    .mat-form-field-required-marker {
      color: #fff !important;
    }

    .mat-form-field-ripple {
      background: var(--color-text-secondary) !important;
    }
  }
}

.field {
  $root: &;

  width: 100%;
  padding: 0;

  &[class*='field--'] {
    @include breakpoint($screen-tablet) {
      & ~ & {
        padding-left: 1.6rem;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--half {
    @include breakpoint($screen-tablet) {
      width: 50%;
    }
  }

  &--small {
    @include breakpoint($screen-tablet) {
      width: 22.222%;
    }
  }

  &--short {
    @include breakpoint($screen-tablet) {
      width: 25%;
    }
  }

  &--third {
    @include breakpoint($screen-tablet) {
      width: 33.333%;
    }
  }

  &--long {
    @include breakpoint($screen-tablet) {
      width: 66.667%;
    }
  }

  &__label {
    color: var(--color-primary);
    text-transform: uppercase;
  }

  &__text {
    display: inline-block;
    width: 100%;
    padding: 0.4rem 0;
    overflow: hidden;
    font-size: 14px;
    color: var(--color-text-primary);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  textarea {
    color: var(--color-text-primary);
  }

  &--switches {
    padding-bottom: 1.25em;
  }

  .link {
    margin-left: 1rem;
  }
}

.error {
  font-size: 1.1rem;
  color: #f00;
}

.link,
.link:visited {
  font-size: 1.2rem;
  color: $theme-dark-gray;
  cursor: pointer;
}

.mat-form-field-label {
  font-size: 1.2rem;
  text-transform: capitalize;
}

.mat-form-field {
  &.disabled, &.mat-form-field-disabled {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      cursor: not-allowed;
      content: '';
      background: rgba(255, 255, 255, 0.6);
    }
  }

  &-type-mat-select {
    width: 100%;
  }

  &--label-primary {
    color: var(--color-primary);
  }
}

.fields-block {
  position: relative;
  display: block;

  @include breakpoint($screen-tablet) {
    display: flex;
    align-items: baseline;
  }

  &.blocked {
    cursor: not-allowed;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      content: ' ';
      background: rgba(255, 255, 255, 0.6);
    }
  }

  &--readonly {
    align-items: flex-start;

    .field {
      margin: 1rem 0;
    }
  }
}

//textarea.mat-input-element {
//  //overflow: hidden !important;
//}

textarea.cdk-textarea-autosize {
  box-sizing: content-box !important;
}

._common-tooltipText {
  width: calc(100% - 20px) !important;
}

._common-editable-field {
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.28125em) scale(1);
  }
}
