@use '../mixins/responsive' as mr;
@use '../variables/responsive' as r;
@use '../mixins/breakpoint' as b;

._common-button {
  &_is-loading {
    .mdc-button__label, .mat-icon {
      display: none;
    }
  }

  &--full {
    --mat3-button-width: 100%;
  }

  &--rounded {
    border: 1px solid var(--color-primary) !important;

    &.mat-mdc-button-base {
      height: 32px !important;
      line-height: 28px;
    }

    &-vertical {
      margin-top: 1.2rem !important;
    }

    .mdc-button__label {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  &--no-focus-state:focus {
    background-color: #fff !important;

    .mat-mdc-button-base.mat-mdc-unelevated-button {
      box-shadow: none !important;
    }
  }

  &--circle {
    --mat3-button-border-radius: 50%;
    --mat3-button-width: 40px;
    --mat3-button-min-width: 40px;
    --mat3-button-height: 40px;
    --app-mat3-button-padding: 0;
    --app-mat3-button-icon-size: 18px;
  }

  &--circle-sm {
    &.mat-mdc-button-base {
      --mat3-button-width: 2.5rem;
      --mat3-button-height: 24px;
      --mat3-button-border-radius: 50%;
      --mat3-button-min-width: 2.5rem;
      --app-mat3-button-padding: 0;
      --app-mat3-button-icon-size: 16px;
    }
  }

  &--public {
    &.mat-mdc-button-base {
      background: #fff;

      &[disabled] {
        background: #fff;

        .mdc-button__label {
          color: var(--color-primary);
        }
      }
    }

    .mdc-button__label {
      color: var(--color-primary);
    }
  }

  &--white {
    &.mat-mdc-button-base {
      background: #fff;
    }

    .mdc-button__label {
      color: var(--color-primary);
    }
  }

  &--grid-action-button {
    line-height: 1;
    box-shadow: none;

    &:hover {
      opacity: 1;
    }

    &.mat-mdc-button-base {
      --app-mat3-button-icon-margin-right: 8px;
      --mat3-button-min-width: 85px;
      --app-mat3-button-padding: 0 12px;
      --mat3-storked-button-background: var(--color-primary);
      --mat3-button-color: #fff;

      border-color: #fff !important;
    }

    &.mat-mdc-unelevated-button {
      --mat3-flat-button-background: #fff;
      --mat3-button-color: var(--color-primary);
    }
  }

  &__vertical-button {
    & ~ & {
      margin-top: 1.2rem;
    }
  }

  &__two-lines {
    position: relative;
    bottom: 8px;

    .mdc-button__label {
      height: 28px;
      font-size: 10px;
      line-height: 14px;
    }
  }

  &_submit-disabled {
    --mat3-button-background: rgba(0, 0, 0, 0.12) !important;
    --mat3-button-color: rgba(0, 0, 0, 0.38) !important;
  }
}

.inline-buttons {
  display: flex;
  gap: 16px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @include mr.user-tiles-1() {
    gap: 0;
  }

  @include b.breakpoint(r.$screen-tablet) {
    width: inherit;
    min-width: inherit;
  }

  &--right {
    justify-content: flex-end;

    .mat-mdc-button-base {
      & ~ & {
        margin-left: 2rem;
      }
    }
  }

  &__button {
    & ~ & {
      margin-bottom: 0;
      margin-left: 2rem;

      @include mr.user-tiles-1() {
        margin-bottom: 2rem;
        margin-left: 0;
      }
    }
  }

  &--center {
    justify-content: center;

    .mat-mdc-button-base {
      & ~ & {
        margin-left: 3.2rem;
      }
    }
  }

  &--primary-color {
    color: var(--color-primary);
  }

  @include mr.user-tiles-1() {
    flex-direction: column-reverse;
  }
}

.mat-button.mat-button-disabled,
.mat-icon-button.mat-button-disabled,
.mat-stroked-button.mat-button-disabled,
.mat-flat-button.mat-button-disabled,
.mat-button-disabled {
  cursor: not-allowed !important;
}

._common-mat-button {
  &--stroked-invert {
    &.mat-mdc-outlined-button {
      border-color: #fff !important;
    }

    .mdc-button__label {
      color: #fff;
    }
  }

  &--invert {
    --mat3-flat-button-background: #fff;
    --mat3-button-color: var(--color-primary) !important;
  }
}
