.mat-mdc-card {
  --mdc-elevated-card-container-shape: 10px;
}

.mat-mdc-card-header {
  $mat-card-header-padding-default: 16px 16px 0;
  $mat-card-header-border-bottom-default: none;

  padding: var(--mat-card-header-padding, $mat-card-header-padding-default) !important;
  border-bottom: var(--mat-card-header-border-bottom, $mat-card-header-border-bottom-default) !important;
}

.mat-mdc-card-content {
  $mat-card-content-padding-top-default: 0;

  padding-top: var(--mat-card-content-padding-top, $mat-card-content-padding-top-default) !important;
}

.mat-mdc-card-title {
  color: var(--mat-card-title-color, var(--color-text-primary, #000));
}

.mat-mdc-card-subtitle {
  color: var(--color-text-primary);
}
