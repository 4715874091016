@use 'libs';
@use 'common';
@use 'elements';
@use 'variables/responsive' as r;
@use 'mixins/breakpoint' as b;
@use 'mixins/print' as p;
@use 'variables/theme' as t;

:root {
  --mdc-dialog-supporting-text-size: 14px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #a0a0a5 var(--scrollbar-background, transparent);

  &::-webkit-scrollbar {
    z-index: 1;
    width: 6px;
    height: 6px;
    background-color: var(--scrollbar-background, transparent);
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ///* scrollbar itself */
  //&::-webkit-scrollbar-thumb {
  //  background-color: rgba(0,0,0,0);
  //  border-radius: 16px;
  //  border: 0 solid #fff;
  //}

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #a0a0a5;
    border-radius: 16px;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }

  ///* scrollbar when element is hovered */
  //&:hover::-webkit-scrollbar-thumb {
  //  background-color: #a0a0a5;
  //  border: 4px solid #fff;
  //}
  //
  ///* scrollbar when scrollbar is hovered */
  //&::-webkit-scrollbar-thumb:hover {
  //  background-color: #a0a0a5;
  //  border: 4px solid #f4f4f4
  //}
}

.custom-overlay-scrollbar {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #a0a0a5 transparent;

  @include p.print() {
    overflow: initial;
  }

  &::-webkit-scrollbar {
    z-index: 1;
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ///* scrollbar itself */
  //&::-webkit-scrollbar-thumb {
  //  background-color: rgba(0,0,0,0);
  //  border-radius: 16px;
  //  border: 0 solid #fff;
  //}

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #a0a0a5;
    border-radius: 16px;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }

  ///* scrollbar when element is hovered */
  //&:hover::-webkit-scrollbar-thumb {
  //  background-color: #a0a0a5;
  //  border: 4px solid #fff;
  //}
  //
  ///* scrollbar when scrollbar is hovered */
  //&::-webkit-scrollbar-thumb:hover {
  //  background-color: #a0a0a5;
  //  border: 4px solid #f4f4f4
  //}
}

body {
  margin: 0;
  overflow: hidden;
  color: var(--color-text-primary);
  font-size: 14px;
  font-family: var(--base-font);
  line-height: 1;
}

h2 {
  color: var(--color-text-primary);
  font-size: 2.4rem;
}

h3 {
  color: var(--color-text-primary);
  font-size: 2rem;
}

h4 {
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
}

fieldset {
  padding: 0;
}

.italic {
  font-style: italic;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.information-list {
  line-height: 1.8rem;
}

.ellipsis {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.clearfix::after {
  display: block;
  clear: both;
  visibility: hidden;
  content: '';
}

.base-loader {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  color: var(--color-primary);
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(75, 82, 109, 0.1);
}

.no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16rem;
  color: var(--color-text-primary);
  font-size: 2rem;
}

.mat-form-field:not(.mat-focused) {
  .mat-form-field-underline,
  .mat-form-field-ripple,
  &.mat-focused .mat-form-field-ripple,
  &.mat-focused .mat-form-field-underline {
    background-color: #9ea4bb;
  }
}

.mat-form-field-appearance-outline .mat-form-field-underline,
.mat-form-field-appearance-outline.mat-form-field-invalid:not(.mat-focused) .mat-form-field-ripple {
  height: 2px !important;
}

.mat-form-field-ripple {
  transform: none !important;
  opacity: 1 !important;
}

input.mat-input-element {
  color: var(--color-text-primary);
}

.mat-form-field-appearance-outline .mat-form-field-suffix .mat-icon-button .icon.input-date-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-bottom: 5px;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-form-field.mat-form-field-invalid .mat-form-field-underline,
.mat-error {
  color: t.$red;
}

.mat-error {
  white-space: pre-line;
}

.mat-datepicker-popup {
  color: currentColor;
}

.mat-checkbox .mat-checkbox-ripple {
  display: none;
}

.mat-checkbox-frame {
  border-color: rgba(1, 1, 1, 0.54);
}

.mat-calendar-body-selected {
  color: #fff;
}

.select {
  &--loading {
    //overflow: hidden !important;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: var(--scroll-loading-overlay-height, 100%);
      background: rgba(75, 82, 109, 0.1);
      content: '';
    }
  }

  &--custom-search {
    padding-top: 8px !important;
  }

  &--search {
    max-height: 256px;

    .select__content {
      max-height: calc(256px - 42px) !important;
    }
  }

  &--table {
    max-height: 256px;

    .select__content {
      max-height: calc(256px - 84px) !important;
    }
  }
}

.mat-option-text {
  color: var(--color-text-primary);
  line-height: 1.2;
}

.mat-calendar-controls {
  .mat-icon-button[disabled] {
    color: t.$sub-text;
    cursor: not-allowed;
  }
}

.mat-calendar-body-disabled {
  background: t.$gray !important;
  cursor: not-allowed !important;

  .mat-calendar-body-today {
    background: t.$gray !important;

    &:hover {
      background: t.$gray !important;
      border-color: rgba(0, 0, 0, 0.38) !important;
    }
  }
}

.mat-icon {
  line-height: 1;
  text-align: center;
}

.mat-form-field.readonly {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }
}

.mat-select-value-text {
  color: var(--color-text-primary);
}

.mat-option {
  font-size: 16px;

  &[aria-disabled='true'].mat-option-disabled {
    cursor: not-allowed;
    opacity: 0.7;

    &.custom-option-search {
      opacity: 1 !important;
    }
  }

  &.hidden {
    height: 0 !important;
    visibility: hidden;
  }

  &.custom-option-search {
    position: sticky;
    top: -8px;
    z-index: 1;
    margin-top: -8px;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    cursor: default;
  }
}

.modal-loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.default-loader {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 150px);
}

.mat-input-element:disabled {
  color: var(--color-text-primary);
  cursor: not-allowed;
}

.child-option {
  padding-left: 2rem;
}

.tooltip {
  $root: &;

  position: relative;
  display: inline-block;
  margin-left: 1rem;
  font-size: 1.2rem;
  vertical-align: sub;

  &_wide {
    --app-mat3-tooltip-max-width: 460px;
  }

  &:hover {
    #{$root}__text {
      visibility: visible;
    }
  }

  &__input-block {
    position: relative;
  }

  &__input {
    position: absolute;
    right: 0;
    bottom: 0;
    //padding-right: 2.4rem !important;

    &_app-form-input {
      position: absolute;
      right: 0;
      bottom: -6px;
    }
  }

  &__text {
    position: absolute;
    top: -1rem;
    right: -17rem;
    z-index: 10;
    width: 16rem;
    padding: 1.6rem;
    color: #fff;
    text-align: center;
    background-color: rgba(97, 97, 97, 0.9);
    border-radius: 6px;
    visibility: hidden;
  }

  &__bottom {
    position: absolute;
    top: 3rem;
    right: -1rem;
    font-size: 12px;
  }

  &__custom-text {
    z-index: 10;
    width: 16rem;
    padding: 1.6rem;
    color: #fff;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    text-align: center;
    background-color: #101426;
    border-radius: 4px;
    visibility: hidden;
  }

  &__icon {
    --app-mat3-icon-size: 18px;

    #{$root}__datepicker & {
      margin-bottom: 5px;
      //transform: rotate(180deg);
    }

    #{$root}__input {
      margin-bottom: 2px;
      transform: rotate(180deg);
    }

    #{$root}__textarea & {
      margin-bottom: 2px;
      //transform: rotate(180deg);
    }
  }

  &__datepicker {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &--left {
    margin-right: 1rem;
    margin-left: 0;
  }
}

.form-date {
  &__icon-toggle {
    .mat-icon-button {
      bottom: -6px;
    }
  }

  .mat-form-field-suffix {
    bottom: -2px;
    display: flex;
    gap: 4px;
    align-items: center;
    margin-left: 4px;
    padding-bottom: 0.2em;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    width: 36px;
    height: 36px;
  }

  .mat-datepicker-toggle .mat-mdc-button-base {
    --mat3-button-min-width: 32px;

    width: 32px !important;
    height: 32px !important;
    padding: 0 !important;

    svg {
      margin-bottom: -1px;
    }
  }
}

.tenant-preview .mat-dialog-container {
  padding: 0;
}

.mat-dialog-container {
  padding: 0 !important;
}

.help-text {
  margin: 0 0 12px !important;
  color: var(--color-text-primary);
  font-weight: 400;

  &--required {
    &::before {
      padding-left: 4px;
      color: var(--color-warn);
      content: '* ';
    }

    &-right {
      &::after {
        color: var(--color-warn);
        content: '*';
      }
    }
  }

  &--italic {
    //color: var(--color-red-help);
    font-weight: 600;
    font-size: 14px;
    font-style: italic;
    line-height: 1.43;
    text-decoration: underline;
  }

  &--optional {
    margin-left: 8px;
    color: var(--color-optional);
    font-size: 14px;
    font-style: italic;
  }
}

.color-primary {
  color: var(--color-primary);
}

.hidden {
  height: 0;
  visibility: hidden;
}

.invisible {
  opacity: 0;
}

.splide__slide {
  border: 2px solid rgba(0, 0, 0, 0.4);
}

.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border-color: #fff !important;
}

.inquire-icon {
  width: 22px !important;
  height: 22px !important;
}

.checkbox-icon {
  svg {
    position: relative;
    right: 3px;
    bottom: 2px;
    fill: #fff;
  }
}

.mat-drawer.mat-drawer-side {
  z-index: 1 !important;
}

.form-step-buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  margin-top: 2.4rem;
  padding-top: 2.4rem;
  padding-bottom: 16px;
  border-top: 1px solid #9ea4bb;
}

.mat-dialog-container {
  color: var(--color-text-primary) !important;
}

.link-button {
  padding: 0;
  color: var(--color-primary);
  text-decoration: underline;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.cdk-global-scrollblock {
  overflow-y: auto;
}

._common-mat-tooltip {
  &__large {
    font-size: 12px;
  }
}

.files-drop-area {
  &__caption-additional-text {
    display: inline-block;
    width: 100%;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    word-break: break-word;

    &--center {
      display: block;
      min-height: auto;
      margin-top: 16px;
      text-align: center;
    }
  }

  &__drop-area-header {
    height: 40px;
    margin-bottom: 8px;
    color: #4b516c;
    font-weight: 500;
    font-size: 20px;
    font-style: italic;
    line-height: 1.1;
    letter-spacing: 0.15px;
  }

  &__drop-area-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 20px;

    &_three-columns {
      @include b.breakpoint(r.$screen-tablet-lg) {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
      }
    }

    &_two-columns {
      @include b.breakpoint(r.$screen-tablet-lg) {
        display: grid;
        grid-template-columns: 1fr 2fr;
      }
    }
  }

  &__drop-area {
    width: 100%;

    &_small {
      width: 50%;

      @include b.breakpoint(r.$screen-desktop) {
        width: 35%;
      }
    }
  }
}

.photo-dialog {
  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      overflow-y: initial;
      background-color: transparent !important;
    }
  }
}

.prompt-close {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 8px 8px 0;
  color: var(--color-primary);
}

.prompt-title {
  word-break: break-word;
  border-bottom: 0;
}

.prompt-actions {
  justify-content: center !important;
}

.mdc-tooltip {
  white-space: pre-line;

  &__surface {
    max-width: var(--app-mat3-tooltip-max-width, 200px) !important;
    text-align: left !important;
  }
}
