@use '../mixins/responsive' as mr;

._common-radio-group {
  &--radio-top {
    .mat-radio-label {
      align-items: flex-start;
    }

    .mat-radio-label-content {
      width: 100%;
      padding-top: 2px;
    }
  }

  &--no-left-padding {
    .mat-option {
      padding: 0 16px 0 0;
    }
  }
}

.mat-radio-button .mat-radio-ripple {
  height: 0 !important;
  width: 0 !important;
}

.mat-radio-label {
  white-space: nowrap !important;

  @include mr.user-tiles-1() {
    white-space: normal !important;
  }
}
