@import '../variables/theme';

._common-icon {
  &--white {
    --app-mat3-icon-color: #fff;
  }

  &--theme {
    --app-mat3-icon-color: var(--color-primary);
  }

  &--disabled {
    --app-mat3-icon-color: $sub-text;

    cursor: not-allowed;
    pointer-events: none;
  }

  &--red-help {
    --app-mat3-icon-color: var(--color-red-help);
  }
}
