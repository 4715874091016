._common-help {
  &__content {
    padding: 0 !important;
    color: var(--color-text-primary);

    blockquote {
      border-left: 4px solid #ccc;
      margin-bottom: 5px !important;
      margin-top: 5px !important;
      padding-left: 16px !important;
    }
    //a {
    //  display: block;
    //  margin-top: 1rem;
    //  color: var(--color-primary);
    //}
    //
    p {
      margin-bottom: 12px !important;
      line-height: 1.5;
      color: var(--color-text-primary);
    }
  }
}
