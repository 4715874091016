._common-tab {
  color: var(--color-secondary);

  &:hover {
    text-decoration: none;
  }

  &--active {
    font-weight: 600;
    color: var(--color-primary) !important;
  }

  &--dashboard {
    .mat-tab-body-content {
      overflow-y: hidden !important;
    }

    .mat-tab-body-wrapper {
      margin-bottom: 1rem;
    }
  }
}

._common-tab-group {
  &_dialog-header {
    .mat-tab-header {
      margin-bottom: 0;
    }

    .mat-tab-labels {
      padding: 0 16px;
    }
  }
}
