._common-mat-checkbox {
  &--theme-invert {
    &.mat-primary .mat-checkbox-background {
      background-color: #fff;
    }

    .mat-checkbox-mixedmark {
      background-color: var(--color-primary);
    }

    .mat-checkbox-checkmark-path {
      stroke: var(--color-primary) !important;
    }
  }
}
