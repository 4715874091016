.mat-mdc-radio-button {
  &.mat-mdc-radio-checked {
    .mdc-radio__background {
      &::before {
        display: none;
      }
    }
  }

  .mdc-form-field {
    width: 100%;
    align-items: var(--mat3-radio-form-field-align-items, center);

    label {
      width: 100%;
    }
  }

  .mdc-radio {
    padding: var(--mat3-radio-padding, calc((var(--mdc-radio-state-layer-size, 40px) - 20px) / 2)) !important;
  }
}
