@mixin do-not-print {
  @media print {
    display: none !important;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
